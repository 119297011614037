import React from "react"
import styled from "@emotion/styled"

const Box = styled.div({
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "86px",
  backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDYiIGhlaWdodD0iODYiIHZpZXdCb3g9IjAgMCAxMDYgODYiIGZpbGw9Im5vbmUiPgo8ZWxsaXBzZSBjeD0iNTEuNDYzOSIgY3k9IjI4LjAzNjEiIHJ4PSIzMi4yNjA4IiByeT0iMjguMDM2MSIgZmlsbD0iI0Y4RjhGOCIvPgo8ZWxsaXBzZSBjeD0iNzMuNzM5MyIgY3k9IjM4Ljc4OTciIHJ4PSIzMi4yNjA4IiByeT0iMjguMDM2MSIgZmlsbD0iI0Y4RjhGOCIvPgo8ZWxsaXBzZSBjeD0iNDAuNzEiIGN5PSI0OS45Mjc0IiByeD0iNDAuNzEiIHJ5PSIzNS4zMzMyIiBmaWxsPSIjRjhGOEY4Ii8+Cjwvc3ZnPgo=")`,
  width: "106px",
  height: "86px",
})

const DevelopIcon = () => (
  <Box>
    <svg
      width="71"
      height="71"
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.88 70.25H37.13V70V10.66C37.13 9.3586 36.8737 8.06994 36.3756 6.86761C35.8776 5.66527 35.1477 4.5728 34.2274 3.65257C33.3072 2.73234 32.2147 2.00238 31.0124 1.50435C29.8101 1.00633 28.5214 0.75 27.22 0.75H5.14C3.9757 0.75 2.85909 1.21252 2.0358 2.0358C1.21252 2.85909 0.75 3.9757 0.75 5.14V65.86C0.75 67.0243 1.21252 68.1409 2.0358 68.9642L2.21258 68.7874L2.0358 68.9642C2.85909 69.7875 3.9757 70.25 5.14 70.25H36.88ZM4.34097 4.34097C4.55289 4.12905 4.84031 4.01 5.14 4.01H27.22C28.9837 4.01 30.6751 4.71062 31.9223 5.95774C33.1694 7.20486 33.87 8.89631 33.87 10.66V66.99H5.14C4.8403 66.99 4.55288 66.8709 4.34097 66.659L4.16419 66.8358L4.34097 66.659C4.12905 66.4471 4.01 66.1597 4.01 65.86V5.14C4.01 4.84031 4.12905 4.55289 4.34097 4.34097Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M36.88 70.25H37.13V70V68.62C37.13 67.3186 36.8737 66.03 36.3756 64.8276C35.8776 63.6253 35.1477 62.5328 34.2274 61.6126C33.3072 60.6924 32.2147 59.9624 31.0124 59.4644C29.8101 58.9664 28.5214 58.71 27.22 58.71H1H0.75V58.96V65.86C0.75 67.0243 1.21252 68.1409 2.0358 68.9642C2.85909 69.7875 3.9757 70.25 5.14 70.25H36.88ZM33.6685 66.99H5.14C4.8403 66.99 4.55289 66.871 4.34097 66.6591C4.12905 66.4471 4.01 66.1597 4.01 65.86V61.97H27.22H27.2201C28.7531 61.9697 30.2391 62.4991 31.4267 63.4685C32.5347 64.373 33.3193 65.6087 33.6685 66.99Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M33.8691 70V70.25H34.1191H65.8591C67.0234 70.25 68.1401 69.7875 68.9633 68.9642C69.7866 68.1409 70.2491 67.0243 70.2491 65.86V5.14C70.2491 3.9757 69.7866 2.85909 68.9633 2.0358C68.1401 1.21252 67.0234 0.75 65.8591 0.75H43.7791C41.1508 0.75 38.6302 1.79409 36.7717 3.65257C34.9132 5.51106 33.8691 8.0317 33.8691 10.66V70ZM65.8591 66.99H37.1291V10.66C37.1291 8.89631 37.8298 7.20486 39.0769 5.95774C40.324 4.71062 42.0155 4.01 43.7791 4.01H65.8591C66.1588 4.01 66.4463 4.12905 66.6582 4.34097C66.8701 4.55289 66.9891 4.8403 66.9891 5.14V65.86C66.9891 66.1597 66.8701 66.4471 66.6582 66.659C66.4463 66.8709 66.1588 66.99 65.8591 66.99Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M33.8691 70V70.25H34.1191H65.8591C67.0234 70.25 68.1401 69.7875 68.9633 68.9642C69.7866 68.1409 70.2491 67.0243 70.2491 65.86V58.96V58.71H69.9991H43.7791C41.1508 58.71 38.6302 59.7541 36.7717 61.6126C34.9132 63.4711 33.8691 65.9917 33.8691 68.62V70ZM66.9891 61.97V65.86C66.9891 66.1597 66.8701 66.4471 66.6582 66.6591C66.4463 66.871 66.1588 66.99 65.8591 66.99H37.3306C37.6798 65.6087 38.4645 64.373 39.5724 63.4685C40.76 62.4991 42.2461 61.9697 43.7791 61.97H43.7791H66.9891Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M21.9484 12.04V11.79H21.6984H7.89844H7.64844V12.04V14.8V15.05H7.89844H21.6984H21.9484V14.8V12.04Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M26.0884 20.32V20.07H25.8384H7.89844H7.64844V20.32V23.08V23.33H7.89844H25.8384H26.0884V23.08V20.32Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M26.0884 28.6V28.35H25.8384H7.89844H7.64844V28.6V31.36V31.61H7.89844H25.8384H26.0884V31.36V28.6Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M26.0884 36.88V36.63H25.8384H7.89844H7.64844V36.88V39.64V39.89H7.89844H25.8384H26.0884V39.64V36.88Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M26.0884 45.16V44.91H25.8384H7.89844H7.64844V45.16V47.92V48.17H7.89844H25.8384H26.0884V47.92V45.16Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M63.3508 12.04V11.79H63.1008H49.3008H49.0508V12.04V14.8V15.05H49.3008H63.1008H63.3508V14.8V12.04Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M63.3502 20.32V20.07H63.1002H45.1602H44.9102V20.32V23.08V23.33H45.1602H63.1002H63.3502V23.08V20.32Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M63.3502 28.6V28.35H63.1002H45.1602H44.9102V28.6V31.36V31.61H45.1602H63.1002H63.3502V31.36V28.6Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M63.3502 36.88V36.63H63.1002H45.1602H44.9102V36.88V39.64V39.89H45.1602H63.1002H63.3502V39.64V36.88Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
      <path
        d="M63.3502 45.16V44.91H63.1002H45.1602H44.9102V45.16V47.92V48.17H45.1602H63.1002H63.3502V47.92V45.16Z"
        fill="#7FE0AF"
        stroke="#7FE0AF"
        strokeWidth="0.5"
      />
    </svg>
  </Box>
)

export default DevelopIcon
