import React from "react"
import styled from "@emotion/styled"

const Box = styled.div({
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "86px",
  backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODkiIGhlaWdodD0iNjUiIHZpZXdCb3g9IjAgMCA4OSA2NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGVsbGlwc2UgY3g9IjM5LjE0NzciIGN5PSIyMS4zMjY2IiByeD0iMjQuNTQwMiIgcnk9IjIxLjMyNjYiIGZpbGw9IiNGOEY4RjgiLz4KPGVsbGlwc2UgY3g9IjU2LjI4OTUiIGN5PSIzNi41MTI5IiByeD0iMzIuNzA5NCIgcnk9IjI4LjE0NTMiIGZpbGw9IiNGOEY4RjgiLz4KPGVsbGlwc2UgY3g9IjMwLjk2NzQiIGN5PSIzNy45NzkiIHJ4PSIzMC45Njc0IiByeT0iMjYuODc3NCIgZmlsbD0iI0Y4RjhGOCIvPgo8L3N2Zz4K")`,
  width: "89px",
  height: "65px",
})

const CovenantIcon = () => (
  <Box>
    <svg
      width="82"
      height="66"
      viewBox="0 0 82 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M62.4689 58.6736C60.4249 58.6736 58.5787 57.6684 57.4624 56.5522C56.7313 55.8212 56.7313 54.6359 57.4624 53.9046C58.1936 53.1736 59.3791 53.1736 60.1102 53.9046C60.1105 53.9046 60.7768 54.5384 61.6906 54.8147C62.342 55.0115 63.3497 55.1316 64.5766 53.9048C65.8036 52.6778 65.6838 51.67 65.4866 51.0189C65.2092 50.1013 64.5719 49.4335 64.5655 49.427C63.8344 48.6959 63.8401 47.5161 64.5711 46.785C65.3024 46.0539 66.4933 46.0595 67.2244 46.7906C69.1862 48.7525 70.8054 52.9714 67.2244 56.5524C65.6806 58.0962 64.0179 58.6736 62.4689 58.6736Z"
        fill="#7FE0AF"
      />
      <path
        d="M51.8002 62.2323C49.7551 62.2323 47.9076 61.2262 46.7905 60.1093C46.0594 59.3782 46.0594 58.193 46.7905 57.4617C47.5218 56.7306 48.7072 56.7306 49.4383 57.4617C49.4385 57.4617 50.1055 58.0962 51.0204 58.3731C51.6721 58.5704 52.6803 58.6908 53.9071 57.4641C55.1339 56.2373 55.0139 55.2295 54.817 54.578C54.5397 53.6607 53.9023 52.9927 53.8959 52.9863C53.1648 52.2552 53.1704 51.0752 53.9015 50.3443C54.6326 49.6133 55.8237 49.6189 56.5547 50.35C58.5167 52.312 60.1358 56.5307 56.5547 60.1118C55.0115 61.655 53.349 62.2323 51.8002 62.2323Z"
        fill="#7FE0AF"
      />
      <path
        d="M41.1997 65.7124C39.1814 65.7126 37.3234 64.7722 36.1782 63.7233C36.158 63.7047 36.1382 63.6856 36.1187 63.6663L32.5616 60.1092C31.8305 59.3782 31.8305 58.1929 32.5616 57.4616C32.5614 57.4616 33.1953 56.7953 33.4717 55.881C33.6686 55.2297 33.7886 54.2219 32.5618 52.9952C31.335 51.7684 30.3272 51.8882 29.6759 52.0852C28.7583 52.3626 28.0905 52.9999 28.084 53.0063C27.3529 53.7374 26.1729 53.7317 25.442 53.0005C24.7109 52.2694 24.7165 51.0785 25.4476 50.3473C27.4096 48.3854 31.6285 46.7664 35.2094 50.3473C38.0401 53.178 37.6217 56.4069 36.3529 58.605L38.724 60.9761C38.8367 61.0704 39.5049 61.6094 40.3762 61.8597C41.4474 62.1675 42.356 61.9011 43.2359 61.0212C44.1385 60.1184 44.3968 59.1818 44.0486 58.0735C43.7657 57.1728 43.1842 56.5008 43.1786 56.4947C42.4803 55.7321 42.5323 54.5478 43.2951 53.8495C44.0576 53.1514 45.2417 53.2032 45.94 53.9658C47.7935 55.9899 49.3082 60.2442 45.8837 63.6688C44.3955 65.157 42.7503 65.7122 41.1997 65.7124Z"
        fill="#7FE0AF"
      />
      <path
        d="M30.2028 62.2306C28.6538 62.2306 26.9913 61.6532 25.4473 60.1093C23.4862 58.148 22.8519 55.7307 23.6613 53.3027C24.2295 51.5984 25.3245 50.4707 25.4475 50.3477C26.1788 49.6166 27.364 49.6166 28.0953 50.3478C28.8264 51.0789 28.8264 52.2644 28.0953 52.9955C28.0957 52.9955 27.4618 53.6616 27.1852 54.5759C26.9883 55.2271 26.8685 56.2348 28.0953 57.4619C29.3221 58.6887 30.3299 58.5687 30.9812 58.3717C31.8986 58.0945 32.5666 57.4568 32.5731 57.4506C33.3042 56.7195 34.484 56.7252 35.2151 57.4562C35.9462 58.1873 35.9406 59.3784 35.2095 60.1095C34.0931 61.2255 32.2466 62.2306 30.2028 62.2306Z"
        fill="#7FE0AF"
      />
      <path
        d="M69.5821 51.5594C67.5381 51.5594 65.692 50.5542 64.5756 49.438C63.8445 48.707 63.8445 47.5217 64.5756 46.7904C65.3035 46.0625 66.4815 46.0594 67.2135 46.7805C67.2477 46.8131 67.9064 47.429 68.8039 47.7005C69.4552 47.8973 70.4632 48.0174 71.6898 46.7906C72.9168 45.5636 72.797 44.5558 72.5998 43.9047C72.3224 42.9871 71.6851 42.3194 71.6787 42.3128L68.1638 38.7978C68.1434 38.7784 68.1235 38.7585 68.1041 38.7381L50.8958 21.5298H38.2183L35.2095 24.5385C31.539 28.2091 28.2294 29.6918 25.0918 29.0707C22.3162 28.5216 20.2914 26.4966 18.3333 24.5385C17.9812 24.1864 17.7839 23.7082 17.7852 23.2101C17.7865 22.7119 17.986 22.2349 18.3403 21.8844C18.3758 21.849 21.991 18.2694 29.0211 11.2395C35.814 4.44655 50.5809 6.31385 54.5948 6.97247L61.0187 0.548538C61.3699 0.197509 61.846 0.000183105 62.3427 0.000183105C62.8392 0.000183105 63.3154 0.197509 63.6665 0.548538L81.452 18.334C82.183 19.0653 82.183 20.2507 81.452 20.9816L74.6502 27.7836L71.5988 36.9376L74.3378 39.6766C74.4608 39.7996 75.556 40.9276 76.124 42.6316C76.9333 45.0598 76.299 47.4771 74.3378 49.4382C72.7938 50.982 71.1311 51.5594 69.5821 51.5594ZM37.4428 17.7853H51.6712C52.1677 17.7853 52.644 17.9826 52.995 18.3337L68.6386 33.9772L71.2377 26.1797C71.3298 25.9037 71.4844 25.6534 71.69 25.448L77.4802 19.6575L62.3425 4.51976L56.5521 10.3102C56.1099 10.7524 55.476 10.9444 54.8625 10.8224C54.6953 10.789 37.99 7.56538 31.6687 13.8867C27.096 18.4593 23.9657 21.5745 22.3242 23.2056C23.4804 24.2879 24.6193 25.1601 25.8184 25.3973C27.6366 25.7571 29.8424 24.6101 32.5617 21.8908L36.1188 18.3337C36.47 17.9828 36.9461 17.7853 37.4428 17.7853Z"
        fill="#7FE0AF"
      />
      <path
        d="M12.4176 51.5593C10.8686 51.5593 9.20611 50.9821 7.66214 49.4382C4.08126 45.8571 5.7003 41.638 7.66214 39.6762L14.7765 32.562C16.7385 30.6001 20.9574 28.9811 24.5383 32.562C28.1194 36.143 26.5002 40.3618 24.5383 42.3238C23.8072 43.055 22.6218 43.055 21.8905 42.3238C21.1595 41.5927 21.1595 40.4074 21.8905 39.6762C21.8903 39.6762 22.5243 39.0099 22.8006 38.0955C22.9975 37.4442 23.1175 36.4364 21.8907 35.2098C20.6639 33.9829 19.6561 34.1028 19.0048 34.2997C18.0872 34.5772 17.4194 35.2144 17.4129 35.2208L10.3101 42.3236C10.3103 42.3236 9.67658 42.9901 9.40006 43.9044C9.20311 44.5558 9.08329 45.5635 10.3099 46.7904C11.5369 48.0174 12.5447 47.8974 13.196 47.7004C14.1136 47.4232 14.7814 46.7857 14.7881 46.7793C15.5192 46.0481 16.699 46.0539 17.4301 46.7849C18.1612 47.516 18.1556 48.7071 17.4243 49.4382C16.3081 50.5543 14.4614 51.5593 12.4176 51.5593Z"
        fill="#7FE0AF"
      />
      <path
        d="M19.5249 58.6815C17.811 58.6815 16.1687 57.9452 14.7756 56.5521C12.8144 54.5909 12.1801 52.1735 12.9894 49.7455C13.5574 48.0415 14.6526 46.9135 14.7756 46.7905L21.8896 39.6763C22.1079 39.4582 24.1003 37.5469 26.9023 37.5469C28.6163 37.5469 30.2585 38.2831 31.6516 39.6763C35.2325 43.2572 33.6136 47.4761 31.6516 49.4381L24.5372 56.5525C24.3193 56.7702 22.327 58.6815 19.5249 58.6815ZM17.4234 49.4379C17.4236 49.4379 16.7899 50.1044 16.5134 51.0188C16.3162 51.6701 16.1964 52.6777 17.4232 53.9047C18.1182 54.5997 18.8056 54.9374 19.5249 54.9374C20.7789 54.9374 21.8793 53.9154 21.8896 53.9047L29.004 46.7905C29.0036 46.7907 29.6376 46.1242 29.9141 45.21C30.111 44.5585 30.2308 43.5509 29.0038 42.3239C28.3093 41.6292 27.6218 41.2914 26.9023 41.2914C25.6484 41.2914 24.5475 42.3138 24.5372 42.3243L17.4234 49.4379Z"
        fill="#7FE0AF"
      />
      <path
        d="M48.113 60.6576C47.6339 60.6576 47.1548 60.4749 46.789 60.1093L39.6772 52.9975C38.9461 52.2664 38.9461 51.081 39.6772 50.3499C40.4083 49.6186 41.5936 49.6186 42.3248 50.3499L49.4368 57.4619C50.1678 58.1929 50.1678 59.3784 49.4368 60.1095C49.0711 60.4749 48.592 60.6576 48.113 60.6576Z"
        fill="#7FE0AF"
      />
      <path
        d="M58.7858 57.1006C58.3067 57.1006 57.8276 56.9178 57.4618 56.5522L46.7905 45.8809C46.0594 45.1498 46.0594 43.9646 46.7905 43.2333C47.5218 42.5022 48.7072 42.5022 49.4383 43.2333L60.1096 53.9046C60.8407 54.6359 60.8407 55.8209 60.1096 56.5522C59.7439 56.9178 59.2649 57.1006 58.7858 57.1006Z"
        fill="#7FE0AF"
      />
      <path
        d="M65.8986 49.9863C65.4195 49.9863 64.9404 49.8036 64.5746 49.438L53.9057 38.7691C53.1747 38.038 53.1747 36.8526 53.9057 36.1215C54.6368 35.3902 55.8221 35.3902 56.5533 36.1215L67.2224 46.7906C67.9535 47.5216 67.9535 48.7069 67.2224 49.4382C66.8567 49.8036 66.3777 49.9863 65.8986 49.9863Z"
        fill="#7FE0AF"
      />
      <path
        d="M12.5429 39.3158C11.759 39.3158 11.0285 38.8197 10.767 38.0352L7.3499 27.7831L0.548167 20.9816C0.196951 20.6304 -0.000187082 20.1539 1.33223e-07 19.6572C1.33223e-07 19.1605 0.197512 18.6843 0.549103 18.3332L18.3524 0.547746C19.0836 -0.182769 20.2683 -0.182582 20.9992 0.548308L27.4232 6.97224C29.6821 6.60118 35.3956 5.83584 41.1487 6.44541C42.1769 6.55437 42.9222 7.47622 42.8132 8.50441C42.7043 9.53279 41.783 10.2779 40.7542 10.1689C34.0734 9.46108 27.2255 10.8085 27.1571 10.8221C26.5432 10.945 25.9084 10.7529 25.4658 10.3103L19.6747 4.51915L4.52032 19.6582L10.31 25.4478C10.5155 25.6536 10.6704 25.904 10.7623 26.1796L14.3192 36.8509C14.6463 37.8319 14.1161 38.8919 13.1351 39.2188C12.9388 39.2847 12.7391 39.3158 12.5429 39.3158Z"
        fill="#7FE0AF"
      />
    </svg>
  </Box>
)

export default CovenantIcon
