import React from "react"
import styled from "@emotion/styled"

const Box = styled.div({
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "86px",
  backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4OSIgaGVpZ2h0PSI2NSIgdmlld0JveD0iMCAwIDg5IDY1IiBmaWxsPSJub25lIj4KPGVsbGlwc2UgY3g9IjM5LjE0NzciIGN5PSIyMS4zMjY2IiByeD0iMjQuNTQwMiIgcnk9IjIxLjMyNjYiIGZpbGw9IiNGOEY4RjgiLz4KPGVsbGlwc2UgY3g9IjU2LjI4OTUiIGN5PSIzNi41MTI5IiByeD0iMzIuNzA5NCIgcnk9IjI4LjE0NTMiIGZpbGw9IiNGOEY4RjgiLz4KPGVsbGlwc2UgY3g9IjMwLjk2NzQiIGN5PSIzNy45NzkiIHJ4PSIzMC45Njc0IiByeT0iMjYuODc3NCIgZmlsbD0iI0Y4RjhGOCIvPgo8L3N2Zz4K")`,
  width: "89px",
  height: "65px",
})

const QualityIcon = () => (
  <Box>
    <svg
      width="67"
      height="67"
      viewBox="0 0 67 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.5 0C26.8743 0 20.3975 1.96474 14.8884 5.64577C9.37936 9.32679 5.08559 14.5588 2.55005 20.6801C0.0145153 26.8014 -0.648897 33.5372 0.643708 40.0355C1.93631 46.5339 5.12688 52.503 9.81194 57.1881C14.497 61.8731 20.4661 65.0637 26.9645 66.3563C33.4629 67.6489 40.1986 66.9855 46.3199 64.45C52.4412 61.9144 57.6732 57.6206 61.3542 52.1116C65.0353 46.6025 67 40.1257 67 33.5C67 24.6152 63.4706 16.0944 57.1881 9.81192C50.9056 3.52945 42.3848 0 33.5 0ZM33.5 62.5333C27.7578 62.5333 22.1445 60.8305 17.37 57.6403C12.5955 54.4501 8.87418 49.9157 6.67671 44.6106C4.47925 39.3054 3.90429 33.4678 5.02455 27.8359C6.14481 22.204 8.90996 17.0307 12.9703 12.9703C17.0307 8.90995 22.204 6.14479 27.8359 5.02453C33.4678 3.90428 39.3054 4.47923 44.6106 6.6767C49.9157 8.87416 54.4501 12.5954 57.6403 17.3699C60.8306 22.1444 62.5333 27.7577 62.5333 33.5C62.5333 41.2001 59.4745 48.5849 54.0297 54.0297C48.5849 59.4745 41.2001 62.5333 33.5 62.5333Z"
        fill="#7FE0AF"
      />
      <path
        d="M48.3494 43.416C46.7167 45.8574 44.5074 47.8586 41.9169 49.2426C39.3264 50.6266 36.4347 51.3506 33.4977 51.3506C30.5607 51.3506 27.669 50.6266 25.0786 49.2426C22.4881 47.8586 20.2787 45.8574 18.6461 43.416C18.4915 43.1546 18.2852 42.9274 18.0399 42.7484C17.7945 42.5694 17.5153 42.4423 17.2191 42.3749C16.923 42.3075 16.6162 42.3012 16.3175 42.3564C16.0189 42.4116 15.7346 42.5272 15.4822 42.696C15.2297 42.8648 15.0143 43.0834 14.8492 43.3383C14.684 43.5932 14.5726 43.8791 14.5218 44.1785C14.4709 44.4779 14.4817 44.7846 14.5533 45.0797C14.625 45.3749 14.7562 45.6523 14.9387 45.895C16.98 48.9444 19.7412 51.4438 22.9782 53.1723C26.2152 54.9007 29.8282 55.8049 33.4977 55.8049C37.1673 55.8049 40.7803 54.9007 44.0173 53.1723C47.2543 51.4438 50.0155 48.9444 52.0567 45.895C52.2393 45.6523 52.3704 45.3749 52.4421 45.0797C52.5138 44.7846 52.5246 44.4779 52.4737 44.1785C52.4228 43.8791 52.3114 43.5932 52.1463 43.3383C51.9812 43.0834 51.7658 42.8648 51.5133 42.696C51.2608 42.5272 50.9766 42.4116 50.6779 42.3564C50.3793 42.3012 50.0725 42.3075 49.7764 42.3749C49.4802 42.4423 49.2009 42.5694 48.9556 42.7484C48.7102 42.9274 48.504 43.1546 48.3494 43.416Z"
        fill="#7FE0AF"
      />
      <path
        d="M17.8651 26.8C17.8651 25.9115 18.218 25.0594 18.8463 24.4312C19.4745 23.8029 20.3266 23.45 21.2151 23.45C22.1036 23.45 22.9557 23.8029 23.5839 24.4312C24.2122 25.0594 24.5651 25.9115 24.5651 26.8C24.5651 27.3923 24.8004 27.9604 25.2192 28.3792C25.6381 28.798 26.2061 29.0333 26.7984 29.0333C27.3908 29.0333 27.9588 28.798 28.3776 28.3792C28.7965 27.9604 29.0318 27.3923 29.0318 26.8C29.0318 24.7269 28.2082 22.7387 26.7423 21.2728C25.2764 19.8069 23.2882 18.9833 21.2151 18.9833C19.142 18.9833 17.1538 19.8069 15.6879 21.2728C14.222 22.7387 13.3984 24.7269 13.3984 26.8C13.3984 27.3923 13.6337 27.9604 14.0526 28.3792C14.4714 28.798 15.0395 29.0333 15.6318 29.0333C16.2241 29.0333 16.7921 28.798 17.211 28.3792C17.6298 27.9604 17.8651 27.3923 17.8651 26.8Z"
        fill="#7FE0AF"
      />
      <path
        d="M45.7835 18.9833C43.7104 18.9833 41.7222 19.8069 40.2562 21.2728C38.7903 22.7387 37.9668 24.7269 37.9668 26.8C37.9668 27.3923 38.2021 27.9604 38.6209 28.3792C39.0398 28.798 39.6078 29.0333 40.2001 29.0333C40.7924 29.0333 41.3605 28.798 41.7793 28.3792C42.1982 27.9604 42.4335 27.3923 42.4335 26.8C42.4335 25.9115 42.7864 25.0594 43.4147 24.4312C44.0429 23.8029 44.895 23.45 45.7835 23.45C46.6719 23.45 47.524 23.8029 48.1523 24.4312C48.7805 25.0594 49.1335 25.9115 49.1335 26.8C49.1335 27.3923 49.3688 27.9604 49.7876 28.3792C50.2064 28.798 50.7745 29.0333 51.3668 29.0333C51.9591 29.0333 52.5272 28.798 52.946 28.3792C53.3648 27.9604 53.6001 27.3923 53.6001 26.8C53.6001 24.7269 52.7766 22.7387 51.3107 21.2728C49.8448 19.8069 47.8566 18.9833 45.7835 18.9833Z"
        fill="#7FE0AF"
      />
    </svg>
  </Box>
)

export default QualityIcon
