import React from "react"
import styled from "@emotion/styled"

import HealthyIcon from "../ui/images/work-with-us/healthy"
import FamilyIcon from "../ui/images/work-with-us/family"
import DevelopIcon from "../ui/images/work-with-us/develop"
import QualityIcon from "../ui/images/work-with-us/quality"
import CovenantIcon from "../ui/images/work-with-us/covenant"
import FinanceIcon from "../ui/images/work-with-us/finance"

const ContainerBenefits = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000;
  font-family: "Open Sans";
  background: #f0f0f0;
  padding-bottom: 50px;
`

const Title = styled.h2`
  color: #000000;
  max-width: 600px;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 60px;
  padding: 0 20px;
  font-weight: bold;
  font-size: 27.65px;
  line-height: 116%;
  @media (min-width: 1024px) {
    font-size: 33px;
  }
`

const BenefitsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  max-width: 730px;
  margin-bottom: 40px;
  @media (min-width: 858px) {
    justify-content: space-between;
  }
`

const BenefitBox = styled.div({
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "86px",
})

const Benefit = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 15px;
  @media (min-width: 768px) {
    font-size: 18px;
    line-height: 20px;
  }
`

const Benefits = () => (
  <ContainerBenefits>
    <Title>Nuestros beneficios</Title>
    <BenefitsRow>
      <Benefit>
        <HealthyIcon />
        <Text>Salud</Text>
      </Benefit>
      <Benefit>
        <FamilyIcon />
        <Text>Familiares</Text>
      </Benefit>
      <Benefit>
        <DevelopIcon />
        <Text>
          Desarrollo
          <br />
          personal
        </Text>
      </Benefit>
    </BenefitsRow>
    <BenefitsRow>
      <Benefit>
        <QualityIcon />
        <Text>
          Calidad
          <br />
          de vida
        </Text>
      </Benefit>
      <Benefit>
        <CovenantIcon />
        <Text>Convenios</Text>
      </Benefit>
      <Benefit>
        <FinanceIcon />
        <Text>Financieros</Text>
      </Benefit>
    </BenefitsRow>
  </ContainerBenefits>
)

export default Benefits
