import React from "react"
import styled from "@emotion/styled"

const ContainerImages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  color: #000000;
  font-family: "Open Sans";
  background: #fafafa;
  padding-bottom: 50px;
  @media (min-width: 768px) {
    padding-top: 80px;
  }
`

const Title = styled.h3`
  font-family: "Open Sans";
  font-weight: bold;
  font-size: 27.65px;
  line-height: 116%;
  max-width: 304px;
  text-align: center;
  margin-top: 0;
  @media (min-width: 768px) {
    font-size: 33.18px;
    max-width: 520px;
  }
`

const GreatPlaceToWork = styled.img`
  width: 84px;
  height: 143px;
  margin-top: -68px;
`

const WrapperImageBackground = styled.div`
  background: url("/work-with-us/mobile.png");
  min-width: 309px;
  min-height: 402px;
  @media (min-width: 768px) {
    background: url("/work-with-us/tablet.png");
    min-width: 710px;
    min-height: 274px;
  }
  @media (min-width: 1240px) {
    background: url("/work-with-us/desktop.png");
    min-width: 1096px;
    min-height: 422px;
  }
`

const Images = () => (
  <ContainerImages>
    <Title>Se parte de una de las mejores empresas para trabajar</Title>
    <WrapperImageBackground />
    <GreatPlaceToWork
      src="/great-place-to-work-big.png"
      alt="Great Place To Work"
    />
  </ContainerImages>
)

export default Images
