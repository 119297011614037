import React from "react"
import styled from "@emotion/styled"

import PageLayoutExtend from "../ui/page-layout-extend"
import Header from "./header"
import DescriptionCapitaria from "../shared/description-capitaria"
import Testimonials from "./testimonials"
import FormCV from "./form"
import Images from "./images"
import Benefits from "./benefits"

const WorkWithUsPage = () => (
  <PageLayoutExtend header={<Header />}>
    <DescriptionCapitaria />
    <Testimonials />
    <Images />
    <Benefits />
    <FormCV />
  </PageLayoutExtend>
)

export default WorkWithUsPage
