import React from "react"
import styled from "@emotion/styled"

const Box = styled.div({
  backgroundRepeat: "no-repeat",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "86px",
  backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMTciIGhlaWdodD0iODYiIHZpZXdCb3g9IjAgMCAxMTcgODYiIGZpbGw9Im5vbmUiPgo8ZWxsaXBzZSBjeD0iNTEuNDYzOSIgY3k9IjI4LjAzNjEiIHJ4PSIzMi4yNjA4IiByeT0iMjguMDM2MSIgZmlsbD0iI0Y4RjhGOCIvPgo8ZWxsaXBzZSBjeD0iNzQiIGN5PSI0OCIgcng9IjQzIiByeT0iMzciIGZpbGw9IiNGOEY4RjgiLz4KPGVsbGlwc2UgY3g9IjQwLjcxIiBjeT0iNDkuOTI3NCIgcng9IjQwLjcxIiByeT0iMzUuMzMzMiIgZmlsbD0iI0Y4RjhGOCIvPgo8L3N2Zz4=")`,
  width: "117px",
  height: "86px",
})

const FinanceIcon = () => (
  <Box>
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.5584 35.9371C66.5584 35.3044 66.5584 34.5452 66.5584 33.9125C66.4319 32.394 67.5707 31.2552 69.0892 31.1286C70.6077 31.0021 71.7465 32.1409 71.8731 33.6594C71.8731 34.4186 71.9996 35.1779 71.9996 35.9371C71.9996 37.4556 70.8608 38.5944 69.3423 38.5944C67.6973 38.5944 66.5584 37.4556 66.5584 35.9371Z"
        fill="#7FE0AF"
      />
      <path
        d="M64.2801 24.2954C64.027 23.6627 63.7739 23.0301 63.3943 22.5239C62.7616 21.2585 63.2678 19.6135 64.6597 18.9808C65.9251 18.3481 67.5701 18.8543 68.2028 20.2462C68.5824 21.0054 68.8355 21.6381 69.2151 22.3974C69.7213 23.7893 69.0886 25.3078 67.8232 25.8139C67.4436 25.9405 67.1905 26.067 66.8109 26.067C65.672 25.9405 64.6597 25.3078 64.2801 24.2954Z"
        fill="#7FE0AF"
      />
      <path
        d="M57.5732 14.2989C57.067 13.7927 56.5609 13.4131 56.0547 12.9069C54.9159 11.8946 54.7893 10.2496 55.8017 9.11076C56.814 7.97191 58.459 7.84537 59.5978 8.85768C60.2305 9.36384 60.7367 9.86999 61.3694 10.5027C62.3817 11.515 62.3817 13.16 61.3694 14.2989C60.8632 14.805 60.2305 15.0581 59.4713 15.0581C58.8386 15.0581 58.0794 14.805 57.5732 14.2989Z"
        fill="#7FE0AF"
      />
      <path
        d="M47.7063 7.71885C47.0736 7.46578 46.4409 7.2127 45.8082 6.95962C44.4163 6.45346 43.657 4.93499 44.1632 3.54306C44.6693 2.15113 46.1878 1.3919 47.5797 1.89805C48.339 2.15113 49.0982 2.40421 49.7309 2.78383C51.1228 3.28998 51.7555 4.93499 51.1228 6.20039C50.7432 7.2127 49.7309 7.84539 48.7186 7.84539C48.339 7.84539 48.0859 7.84539 47.7063 7.71885Z"
        fill="#7FE0AF"
      />
      <path
        d="M31.1287 2.9104C31.0022 1.39193 32.1411 0.253078 33.6595 0.126539C34.4188 0.126539 35.178 0 35.9372 0C37.4557 0 38.5946 1.13885 38.5946 2.65732C38.5946 4.17579 37.4557 5.31464 35.9372 5.31464C35.3045 5.31464 34.6718 5.31464 34.0391 5.31464H33.9126C32.3941 5.44118 31.2553 4.30233 31.1287 2.9104Z"
        fill="#7FE0AF"
      />
      <path
        d="M0 35.9371C0 55.6772 16.0705 71.8742 35.9371 71.8742C52.6403 71.8742 66.8127 60.3592 70.7354 44.7949C71.115 43.2764 69.9762 41.8845 68.4577 41.8845C68.2046 41.8845 68.0781 41.8845 67.825 41.8845C66.6861 41.8845 65.8004 42.5171 65.5473 43.656C62.1307 56.9426 49.983 66.6861 35.684 66.5596C18.9809 66.4331 5.44118 52.8934 5.31464 36.1902C5.18811 22.2709 14.4255 10.5027 27.0794 6.58003C27.7121 6.32695 28.0917 5.69426 28.0917 5.06156C27.9652 4.30233 27.9652 3.54309 27.9652 2.65732C27.9652 1.77154 27.0794 1.13885 26.1936 1.39193C11.1354 5.69426 0 19.487 0 35.9371Z"
        fill="#7FE0AF"
      />
      <path
        d="M50.7424 26.4467H43.6562C43.4031 26.4467 43.2765 26.3201 43.15 26.0671C41.7581 23.2832 38.5946 21.7647 35.6842 21.6382C33.533 21.5117 31.2553 22.524 29.9899 24.2955C28.598 26.3201 29.2307 28.3448 30.7492 30.1163C33.9126 33.5329 38.9742 33.5329 43.15 34.7983C45.5543 35.5575 47.4523 36.8229 48.8443 38.4679C51.122 41.3783 51.3751 45.4276 49.73 48.7176C47.9585 52.6403 44.0358 54.918 39.9865 55.6772L38.9742 58.7142C38.9742 59.4734 38.3415 60.1061 37.5823 60.1061H35.178C34.4188 60.1061 33.7861 59.4734 33.7861 58.7142L33.0269 55.9303C27.0795 55.9303 22.7772 50.7422 20.373 45.8072C20.1199 45.4276 20.4995 44.9214 20.8791 44.9214H26.953C27.2061 44.9214 27.4591 45.0479 27.4591 45.301C29.3572 49.983 36.3169 51.8811 40.3661 48.591C42.1377 47.1991 42.5173 44.9214 41.3785 42.8968C39.3538 39.4802 35.178 38.5944 31.6349 37.9617C28.7245 37.4556 25.6876 36.5698 23.4099 34.5452C21.2587 32.5206 20.2464 29.4836 20.373 26.5732C20.626 23.5363 22.1445 21.0055 24.5488 19.1074C26.5734 17.4624 28.9776 16.4501 31.3819 15.9439C31.7615 15.8174 32.0146 13.1601 32.0146 13.1601C32.0146 12.4008 32.6473 11.6416 33.533 11.6416H35.6842C36.4434 11.6416 37.2027 12.2743 37.2027 13.1601L37.9619 15.8174C41.8846 16.3236 45.6808 18.3482 48.3381 21.2586C49.3504 22.3974 50.4893 23.9159 50.9954 25.4344C51.5016 25.9405 51.122 26.4467 50.7424 26.4467Z"
        fill="#7FE0AF"
      />
    </svg>
  </Box>
)

export default FinanceIcon
