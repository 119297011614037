import React from "react"

import Layout from "../components/ui/layout"
import SEO from "../components/seo"
import WorkWithUsPage from "../components/work-with-us-page/work-with-us-page"
import { QueryStringProvider } from "@capitaria/capitaria-utms"

const TrabajaConNosotros = () => (
  <QueryStringProvider>
    <Layout>
      <SEO title="Trabaja con nosotros" />
      <WorkWithUsPage />
    </Layout>
  </QueryStringProvider>
)

export default TrabajaConNosotros
