import React from "react"
import styled from "@emotion/styled"

if (typeof window !== "undefined") {
  const smoothscroll = require("smoothscroll-polyfill")

  // kick off the polyfill!
  smoothscroll.polyfill()
}

const HeaderContainer = styled.div`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  background-image: url("/extend/mobile.jpg");
  background-size: cover;
  min-height: 124px;
  padding-bottom: 30px;
  width: 100%;
  min-height: 50vh;

  @media (min-width: 460px) {
    min-height: 360px;
  }

  @media (min-width: 736px) {
    padding-top: 60px;
    min-height: 335px;
    background-image: url("/extend/tablet.jpg");
  }

  @media (min-width: 1024px) {
    padding-top: 90px;
    background-image: url("/extend/desktop.jpg");
    padding-bottom: 40px;
  }

  @media (min-width: 1920px) {
    background-image: url("/extend/widescreen.jpg");
  }
`

const Title = styled.div`
  font-size: 23.04px;
  line-height: 140%;
  color: #ffffff;
  margin-bottom: 15px;
  text-align: center;
  max-width: 260px;

  @media (min-width: 736px) {
    max-width: 350px;
  }
`

const Button = styled.button`
  background: linear-gradient(180deg, #30ab76 0%, #71c552 100%);
  border-radius: 5px;
  border: none;
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  padding: 16px 38px;
  width: 240px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  margin-top: 30px;
  & :hover {
    background: linear-gradient(180deg, #30ab76 0%, #46a457 100%);
    transition: 0.3s;
  }
`

const Header = () => {
  const scroll = () => {
    if (typeof window !== "undefined") {
      const selector: any = window.document.querySelector(".work-with-us")

      if (selector) {
        window.scroll({
          top: selector.offsetTop,
          behavior: "smooth",
        })
      }
    }
  }

  return (
    <HeaderContainer>
      <Title>
        Únete a nuestro equipo <br />y <strong>vive el Trading</strong> desde
        adentro
      </Title>
      <Button onClick={scroll}>TRABAJA CON NOSOTROS</Button>
    </HeaderContainer>
  )
}

export default Header
