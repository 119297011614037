import React from "react"
import styled from "@emotion/styled"

import CustomSlider from "../ui/custom-slider"
import { css } from "@emotion/core"

const WrapperTestimonials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  color: #000000;
  font-family: "Open Sans";
  background: #eeeeee;
  padding-bottom: 50px;
`

const TestimonialWrapper = styled.div`
  width: 320px;
  margin: 0 4px;
  scroll-snap-align: start;
  flex-shrink: 0;
  text-align: center;
  @media (min-width: 768px) {
    width: 400px;
  }
  @media (min-width: 984px) {
    margin: 0 25px;
    width: 278px;
  }
`

const Title = styled.h4`
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
  @media (min-width: 768px) {
    line-height: 140%;
  }
`

const Body = styled.p`
  font-size: 14px;
  line-height: 140%;
`

const Author = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 0;
`

const Profession = styled.p`
  font-size: 12px;
  line-height: 140%;
  margin-top: 3px;
`

const slidesCSS = css`
  @media (min-width: 768px) {
    width: 400px;
  }
  @media (min-width: 984px) {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 984px) {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
`

const dotsCSS = css`
  display: flex;
  @media (min-width: 814px) {
    display: flex;
  }
  @media (min-width: 984px) {
    display: none;
  }
`

type CustomerTestimonial = {
  image: string
  title: string
  body: string
  author: string
  profession: string
}

const testimonials: CustomerTestimonial[] = [
  {
    image: "/work-with-us/worker1.png",
    title: "He podido crecer en lo profesional y personal",
    body:
      "Capitaria es un lugar único para trabajar, principalmente por su cultura y personas. Ambos aspectos la hacen una empresa que busca potenciar nuevos talentos. Me gusta porque he podido crecer en lo profesional y personal, sin lugar a dudas una gran familia de la que me siento orgulloso de formar parte.",
    author: "Nicolás Müller",
    profession: "Trading Executive",
  },
  {
    image: "/work-with-us/worker2.png",
    title: "El mejor lugar para seguir creciendo",
    body:
      "Como egresado es gratificante encontrar un lugar de trabajo que potencie mis habilidades y que día a día desafié mis talentos orientado a logros personales y profesionales. Capitaria es un equipo humano, que valora quien soy y saca la mejor versión de mí.",
    author: "Gabriela Mellado",
    profession: "Senior Trading Planner",
  },
  {
    image: "/work-with-us/worker3.png",
    title: "Realmente recomiendo Capitaria",
    body:
      "Trabajar en Capitaria es pertenecer a una familia, donde se comparte una cultura y ambiente impresionante. Siempre buscando potenciar a quienes pertenecemos a ella, desafiándonos a ser mejores y dando las instancias para creer personal y laboralmente.",
    author: "Sergio Rivadeneira",
    profession: "Trading Planner",
  },
]

type Props = {
  data: CustomerTestimonial
}

const Testimonial = ({
  data: { image, title, body, author, profession },
}: Props) => (
  <TestimonialWrapper>
    <img src={image} alt={author} />
    <Title>{title}</Title>
    <Body>{body}</Body>
    <Author>{author}</Author>
    <Profession>{profession}</Profession>
  </TestimonialWrapper>
)

const Testimonials = () => {
  return (
    <WrapperTestimonials>
      <CustomSlider
        dataSlides={testimonials}
        slidesCSS={slidesCSS}
        dotsCSS={dotsCSS}
      >
        <Testimonial data={testimonials[0]} />
      </CustomSlider>
    </WrapperTestimonials>
  )
}

export default Testimonials
